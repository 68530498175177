export default [

  // *--------- Organization ---- ---------------------------------------*
    {
      path: '/organization/list',
      name: 'organization-list',
      component: () => import('@/views/organization/list/List.vue'),
      meta: {
        resource: 'Organization',
        action: 'read',
        breadcrumb: [
          {
            text: 'Organization',
          },
          {
            text: 'All',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organization/:id/edit',
      name: 'organization-edit',
      component: () => import('@/views/organization/edit/Edit.vue'),
      meta: {
        resource: 'Organization',
        action: 'edit',
        breadcrumb: [
          {
            text: 'Organization',
            to: {name: 'organization-list'}
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organization/:id/profile',
      name: 'organization-view',
      component: () => import('@/views/organization/view/View.vue'),
      meta: {
        resource: 'Organization',
        action: 'View',
        breadcrumb: [
          {
            text: 'Organization',
            to: {name: 'organization-list'}
          },
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },

  // *--------- Designation ---- ---------------------------------------*
    {
      path: '/designation/list',
      name: 'designation-list',
      component: () => import('@/views/designation/list/List.vue'),
      meta: {
        resource: 'Designation',
        action: 'view',
        breadcrumb: [
          {
            text: 'Designation',
          },
          {
            text: 'All',
            active: true,
          },
        ],
      },
    },

  // *--------- Logs ---- ---------------------------------------*
    {
      path: '/logs',
      name: 'logs-list',
      component: () => import('@/views/logs/list/List.vue'),
      meta: {
        resource: 'Log',
        action: 'view',
        breadcrumb: [
          {
            text: 'Logs',
            active: true,
          },
        ],
      },
    },

  // *--------- Role ---- ---------------------------------------*
    {
      path: '/roles',
      name: 'role-list',
      component: () => import('@/views/role/list/List.vue'),
      meta: {
        resource: 'Role',
        action: 'view',
        breadcrumb: [
          {
            text: 'Role',
            active: true,
          },
        ],
      },
    },
    {
      path: '/role/add-new',
      name: 'role-add-new',
      component: () => import('@/views/role/list/AddNew.vue'),
      meta: {
        resource: 'Role',
        action: 'create',
        breadcrumb: [
          {
            text: 'Role',
            to: {name: 'role-list'}
          },
          {
            text: 'Create',
            active: true,
          },
        ]
      },
    },
    {
      path: '/role/:id/details',
      name: 'role-view',
      component: () => import('@/views/role/list/View.vue'),
      meta: {
        resource: 'Role',
        action: 'view',
        breadcrumb: [
          {
            text: 'Role',
            to: {name: 'role-list'}
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/role/:id/edit',
      name: 'role-edit',
      component: () => import('@/views/role/list/Edit.vue'),
      meta: {
        resource: 'Role',
        action: 'edit',
        breadcrumb: [
          {
            text: 'Role',
            to: {name: 'role-list'}
          },
          {
            text: 'Details',
            to: {name: 'role-view'}
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

  // *--------- Users ---- ---------------------------------------*
    {
      path: '/users',
      name: 'user-list',
      component: () => import('@/views/user/list/List.vue'),
      meta: {
        resource: 'User',
        action: 'view',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/:id/profile',
      name: 'user-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        resource: 'User',
        action: 'view',
        breadcrumb: [
          {
            text: 'User',
            to: {name: 'user-list'}
          },
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/:id/edit',
      name: 'user-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        resource: 'User',
        action: 'edit',
        breadcrumb: [
          {
            text: 'User',
            to: {name: 'user-list'}
          },
          {
            text: 'Profile',
            to: {name: 'user-view'}
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },


  // *--------- my profile ---- ---------------------------------------*

    {
      path: '/my-account/profile',
      name: 'my-profile',
      component: () => import('@/views/my_account/profile/Profile.vue'),
      
      meta: {
        action: 'read',
        resource: 'ACL',
        pageTitle: 'My Profile',
        breadcrumb: [
          {
            text: 'My Profile',
            active: true,
          },
        ],
      },
    },
    {
    path: '/my-account/settings',
    name: 'my-profile-settings',
    component: () => import('@/views/my_account/account-setting/AccountSetting.vue'),
    
    meta: {
      action: 'read',
      resource: 'ACL',
      pageTitle: 'Profile Settings',
      breadcrumb: [
        {
          text: 'Profile Settings',
          active: true,
        },
      ],
    },
  },

  // *--------- Module ---- ---------------------------------------*
    {
      path: '/module/list',
      name: 'module-list',
      component: () => import('@/views/module/list/List.vue'),
      meta: {
        resource: 'Module',
        action: 'read',
        breadcrumb: [
          {
            text: 'Module',
          },
          {
            text: 'All',
            active: true,
          },
        ],
      },
    },
    {
      path: '/module/add-new',
      name: 'module-add-new',
      component: () => import('@/views/module/list/AddNew.vue'),
      meta: {
        resource: 'Module',
        action: 'create',
        breadcrumb: [
          {
            text: 'Module',
            to: {name: 'module-list'}
          },
          {
            text: 'Create',
            active: true,
          },
        ]
      },
    },
    {
      path: '/module/:id/details',
      name: 'module-view',
      component: () => import('@/views/module/list/View.vue'),
      meta: {
        resource: 'Module',
        action: 'view',
        pageTitle: 'Details',
        breadcrumb: [
          {
            text: 'Module',
            to: {name: 'module-list'}
          },
        ],
      },
    },
    {
      path: '/module/:id/edit',
      name: 'module-edit',
      component: () => import('@/views/module/list/Edit.vue'),
      meta: {
        resource: 'Module',
        action: 'edit',
        pageTitle: 'Edit',
        breadcrumb: [
          {
            text: 'Module',
            to: {name: 'module-list'}
          },
          {
            text: 'Details',
            to: {name: 'module-view'}
          },
        ],
      },
    },


  // *--------- Package ---- ---------------------------------------*
    {
      path: '/packages',
      name: 'package-list',
      component: () => import('@/views/package/list/List.vue'),
      meta: {
        resource: 'Package',
        action: 'view',
        breadcrumb: [
          {
            text: 'Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/add-new',
      name: 'package-add-new',
      component: () => import('@/views/package/list/AddNew.vue'),
      meta: {
        resource: 'Package',
        action: 'create',
        breadcrumb: [
          {
            text: 'Package',
            to: {name: 'package-list'}
          },
          {
            text: 'Create',
            active: true,
          },
        ]
      },
    },
    {
      path: '/package/:id/details',
      name: 'package-view',
      component: () => import('@/views/package/list/View.vue'),
      meta: {
        resource: 'Package',
        action: 'view',
        pageTitle: 'Details',
        breadcrumb: [
          {
            text: 'Packages',
            to: {name: 'package-list'},
          },
        ],
      },
    },
    {
      path: '/package/:id/edit',
      name: 'package-edit',
      component: () => import('@/views/package/list/Edit.vue'),
      meta: {
        resource: 'Package',
        action: 'edit',
        pageTitle: 'Edit',
        breadcrumb: [
          {
            text: 'Packages',
            to: {name: 'package-list'}
          },
          {
            text: 'Details',
            to: {name: 'package-view'}
          },
        ],
      },
    },


]
