export default [

  // *--------- Org Profile ---- ---------------------------------------*
    {
      path: '/organization/profile',
      name: 'organization-profile',
      component: () => import('@/views/organization/profile/View.vue'),
      meta: {
        resource: 'Organization',
        action: 'profile',
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'organization',
            active: true,
          },
        ],
      },
    },

    {
      path: '/organization/settings',
      name: 'organization-settings',
      component: () => import('@/views/organization/profile/Edit.vue'),
      meta: {
        resource: 'Organization',
        action: 'profile',
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'organization',
            active: true,
          },
        ],
      },
    },

    {
      path: '/organization/package/upgrade',
      name: 'organization-package-upgrade',
      component: () => import('@/views/organization/pricing/Upgrade.vue'),
      meta: {
        resource: 'Organization',
        action: 'profile',
        pageTitle: 'Upgrade',
        breadcrumb: [
          {
            text: 'organization',
          },
          {
            text: 'package',
            active: true,
          },
        ],
      },
    },

    {
      path: '/organization/pricing',
      name: 'organization-pricing',
      component: () => import('@/views/organization/pricing/Pricing.vue'),
      meta: {
        resource: 'Organization',
        action: 'profile',
        pageTitle: 'Pricing',
        breadcrumb: [
          {
            text: 'organization',
            active: true,
          },
        ],
      },
    },
]
