import Vue from "vue";

// axios
import axios from "axios";

const BASE_URLS = {
  local: "http://localhost:8000", // change this if needed
  production: "https://hive-app.lhgraphics.com", // DO NOT change this
};

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL:
    process.env.NODE_ENV === "production"
      ? BASE_URLS.production
      : BASE_URLS.local,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
